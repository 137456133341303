import React from 'react'
import { Box } from 'theme-ui'

export const ImageHolder = ({children}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 0px',
      }}
    >
      {children}
    </Box>
  )
}

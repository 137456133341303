import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link, Message } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
import { ImageHolder } from '../../components/help-center/ImageHolder';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Request Opportunities">
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "what-are-they"
    }}>{`What are they`}</h2>
    <p>{`A request opportunity is created when SRM detects you have an opportunity to send a review request.`}</p>
    <p>{`Simple RM automatically finds request opportunites in the information coming from your QuickBooks Online account.`}</p>
    <Message sx={{
      mb: 4
    }} mdxType="Message">
Request opportunities are only visible if your account is connected to QuickBooks Online
    </Message>
    <h2 {...{
      "id": "what-to-do-with-them"
    }}>{`What to do with them`}</h2>
    <p>{`When Simple RM presents you with a request opportunity it is your turn to make a decision.`}</p>
    <p>{`The request opportunity screen allows you to send, snooze or skip requests with a single click.`}</p>
    <p>{`Making a decison on one request opportunity immediately brings up the next one.`}</p>
    <p>{`Here it is in action.`}</p>
    <img {...{
      "style": {
        "width": "300px",
        "border": "solid black 1px"
      },
      "src": "/help-center/request-opportunity.gif"
    }}></img>
    <p>{`Your choices when presented with a request opportunity are to send, snooze or skip it.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Send`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A review request is sent`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Snooze`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Defers the decision for later`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skip`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No request will be sent`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Snoozing is useful when you want to request a review from the customer, but not quite yet.`}</p>
    <p>{`It is recommended you make a decision on all request opportunities presented to you. This helps the system make better recommendations.`}</p>
    <h2 {...{
      "id": "finding-your-request-opportunities"
    }}>{`Finding your Request Opportunities`}</h2>
    <p>{`Request opportunities are accessible in two places.`}</p>
    <h4 {...{
      "id": "srm-emails"
    }}>{`SRM Emails`}</h4>
    <p>{`There is a dedicatated request opportunities email that is sent when you have multiple request opportunities available.`}</p>
    <p>{`Request opportunities can also appear in the reputation warning email and account summary email.`}</p>
    <ImageHolder mdxType="ImageHolder">
    <img style={{
        "maxWidth": "500px",
        "border": "solid black 1px"
      }} src="/help-center/email-request-opportunities.png" />
    </ImageHolder>
    <h4 {...{
      "id": "home-page"
    }}>{`Home Page`}</h4>
    <p>{`If you are already logged into your account you can find your request opportunities front and center on the `}<a parentName="p" {...{
        "href": "https://app.simplereviewmanagement.com"
      }}>{`home page`}</a>{` of the account dashboard.`}</p>
    <ImageHolder mdxType="ImageHolder">
    <img style={{
        "maxWidth": "500px",
        "border": "solid black 1px"
      }} src="/help-center/home-page-request-opportunities.png" />
    </ImageHolder>
    <p>{`Not every account will have request opportunities, even if it is connected to QuickBooks Online.`}</p>
    <p>{`One of the main reasons why an account would not have any request opportunities is becuase they are using Automated Requests.`}</p>
    <p>{`When Automated Requests is active all request opportunities are sent automatically.`}</p>
    <h2 {...{
      "id": "missing-first-name"
    }}>{`Missing first name`}</h2>
    <p>{`If the customer in the request opportunity is missing a first name you will have the option to add it.`}</p>
    <p>{`Add a first name by typing it into the text box before you send the request opportunity.`}</p>
    <p>{`Adding a first name is optional. You can still choose to send a review request to a customer without adding a first name.`}</p>
    <p>{`If SRM can make a guess at the first name based on other customer information a suggestion for the first name will be visible in a button.`}</p>
    <p>{`Clicking the button fills the first name input with the suggestion.`}</p>
    <ImageHolder mdxType="ImageHolder">
    <img style={{
        "maxWidth": "300px",
        "border": "solid black 1px"
      }} src="/help-center/add-first-name.png" />
    </ImageHolder>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      